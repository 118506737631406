import { createGlobalStyle } from "styled-components";

// import Dashboard_act from "../../Assets/images/Dashboard_act.png";
// import dashboardicon from "../../Assets/images/dashboard.png";

// import calendaricon from "../../Assets/images/calendar_today.png";
// import calendar_today_act from "../../Assets/images/calendar_today_act.png";

// import creditcard from "../../Assets/images/credit_card.png";
// import creditcard_act from "../../Assets/images/credit_card_act.png";

// import Docfile from "../../Assets/images/File.png";
// import Docfile_act from "../../Assets/images/File_act.png";

// import HomeWork from "../../Assets/images/homework.png";
// import HomeWorkActive from "../../Assets/images/homework_active.png";

import DashBoardActive from "../../Assets/images/Left-Navbar-Icon/dashboard_active.png";
import DashBoardInActive from "../../Assets/images/Left-Navbar-Icon/dashboard_inactive.png";

import ApptActive from "../../Assets/images/Left-Navbar-Icon/appt_active.png";
import ApptInActive from "../../Assets/images/Left-Navbar-Icon/appt_inactive.png";

import BillingActive from "../../Assets/images/Left-Navbar-Icon/billing_active.png";
import BillingInActive from "../../Assets/images/Left-Navbar-Icon/billing_inactive.png";

import DocumentActive from "../../Assets/images/Left-Navbar-Icon/document_active.png";
import DocumentInActive from "../../Assets/images/Left-Navbar-Icon/document_inactive.png";

import HealthProfileActive from "../../Assets/images/Left-Navbar-Icon/hprofile_active.png";
import HealthProfileInActive from "../../Assets/images/Left-Navbar-Icon/hprofile_inactive.png";

import HomeWorkActive from "../../Assets/images/Left-Navbar-Icon/homework_active.png";
import HomeWorkInActive from "../../Assets/images/Left-Navbar-Icon/homework_inactive.png";

import HelpSupportActive from "../../Assets/images/Left-Navbar-Icon/help&sup_active.png";
import HelpSupportInActive from "../../Assets/images/Left-Navbar-Icon/help&sup_inactive.png";

import SettingsActive from "../../Assets/images/Left-Navbar-Icon/settings_active.png";
import SettingsInActive from "../../Assets/images/Left-Navbar-Icon/settings_inactive.png";
import { globalColor, globalFont } from "../utils/RepeatFunctions/Schedule";

const GlobalStyle = createGlobalStyle`
*,body {
    margin: 0;
    padding: 0;
    font-family: ${globalFont};
    box-sizing: border-box;
    list-style : none;
  }

  #Patient_Portal{
  width: 100%;
  height: 100vh;
  }

  nav a.active ,nav a.active #appointment,nav a.active #billing,nav a.active #document,nav a.active #healthprofile{
    background: rgba(85, 127, 245, 0.1);
    border-radius: 4px;   
    color : ${globalColor};     
  }

  nav a{
    &:hover{
      color : ${globalColor}; 
    }
  }

  nav a:hover > #dashboard_img{
    background-image: url(${DashBoardActive}) !important;
  }

  nav a:hover > #appointment_img{
    background-image: url(${ApptActive}) !important;
  }

  nav a:hover > #billing_img{
    background-image: url(${BillingActive}) !important;
  }

  nav a:hover > #document_img{
    background-image: url(${DocumentActive}) !important;
  }

  nav a:hover > #healthprofile_img{
    background-image: url(${HealthProfileActive}) !important;
  }

  nav a:hover > #homework_img{
    background-image: url(${HomeWorkActive}) !important;
  }

  nav a:hover > #settings_img{
    background-image: url(${SettingsActive}) !important;
  }

nav a:hover > #helpandsupport_img{
    background-image: url(${HelpSupportActive}) !important;
  }

  #dashboard_img{
    background-image: url(${DashBoardInActive}) !important;
  }

  nav a.active > #dashboard_img{
    background-image: url(${DashBoardActive}) !important;
  }

  #appointment_img{
    background-image: url(${ApptInActive}) !important;
  }

  nav a.active > #appointment_img{
    background-image: url(${ApptActive}) !important;
  }

  #billing_img{
    background-image: url(${BillingInActive}) !important;
  }

  nav a.active > #billing_img{
    background-image: url(${BillingActive}) !important;
  }

  #document_img{
    background-image: url(${DocumentInActive}) !important;
  }

  nav a.active > #document_img{
    background-image: url(${DocumentActive}) !important;
  }

  #healthprofile_img{
    background-image: url(${HealthProfileInActive}) !important;
  }

  nav a.active > #healthprofile_img{
    background-image: url(${HealthProfileActive}) !important;
  }

  #homework_img{
    background-image: url(${HomeWorkInActive}) !important;
  }

  nav a.active > #homework_img{
    background-image: url(${HomeWorkActive}) !important;
  }

  #settings_img{
    background-image: url(${SettingsInActive}) !important;
  }

  nav a.active > #settings_img{
    background-image: url(${SettingsActive}) !important;
  }
  
#helpandsupport_img{
    background-image: url(${HelpSupportInActive}) !important;
  }

  nav a.active > #helpandsupport_img{
    background-image: url(${HelpSupportActive}) !important;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #888; 

    &:hover{
      cursor : pointer;
      background: #84A6FE; 
    }
  }

  .Toastify__toast-container{
    width : auto !important;
  }

  .Toastify__toast-theme--light.error_badge {
    border-left: 8px solid #FF8181 !important;
    border-radius: 8px !important;
  }
  
  .Toastify__toast-theme--light {
    border-left: 8px solid #6BFF7B !important;
    border-radius: 8px !important;
  }
  .Toastify__close-button{
    align-self: normal;
    padding-right : 6px !important;
  }
  .Toastify__close-button > svg {
    height: 20px;
    width: 20px;
  }
  .react-datepicker__day--today{
    color : black;
    background-color : white;    
  }
  .react-datepicker__day--selected{
  background-color : ${globalColor};
  border-radius : 50%;
  }
  .react-datepicker__day--keyboard-selected{
    background-color: white !important;
    color: black;
  }
  a{
    text-decoration : none;
  }
  
  `;

export default GlobalStyle;

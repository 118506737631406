import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { clearInput } from "../../../StateManagement/Reducers/AppointmentsState";

import { Div } from "../../StyledComponents";
import { ChangeText } from "./Styles";
import { TextVariant2 } from "./StyledComponents";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

const SelectedOffice = ({ Type }) => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );

  // ------------------------------ State and Variables End--------------------------------------------------- //

  // ------------------------------ Functions Start----------------------------------------------------------- //

  const getDate = () => {
    let a = moment(appointmentData["aptdate"]).format("MMM DD,yyyy");
    return a;
  };

  const getEndTime = () => {
    var timedetnew = moment(appointmentData["apttime"], "hh:mm a").toDate();
    var returned_endatenew = moment(timedetnew, "hh:mm a").add(30, "minutes");
    var endTimenew = moment(returned_endatenew).format("hh:mm a");
    return appointmentData["apttime"] + " - " + endTimenew;
  };

  // ------------------------------ Functions End--------------------------------------------------------------- //

  return (
    <Div
      width={Type === "office" ? "100%" : "55%"}
      height={Type === "office" ? "auto" : "100%"}
      border={`1px solid ${globalColor}`}
      borderRadius="6px"
      position="relative"
      padding="12px 16px 16px 12px"
      display="flex"
    >
      <ChangeText
        mediaWidth={Type === "office" ? "1300px" : "1100px"}
        absolute
        onClick={() => {
          if (Type === "office") {
            dispatch(clearInput("office"));
          } else {
            dispatch(clearInput("time"));
          }
        }}
      >
        Change
      </ChangeText>
      <Div width={Type === "office" ? "42%" : "23%"}>
        <TextVariant2>{Type === "office" ? "Office" : "Date"}</TextVariant2>
        <TextVariant2 margin="6px 0 0">
          {Type === "office" ? "Location" : "Time"}
        </TextVariant2>
      </Div>
      <Div>
        <TextVariant2>
          {Type === "office"
            ? `${appointmentData["singleOffice"]["name"]}`
            : `${getDate()}`}
        </TextVariant2>
        <TextVariant2
          breakAll
          margin="6px 0 0"
          color={Type === "office" ? "#212121" : globalColor}
        >
          {Type === "office"
            ? `${appointmentData["singleOffice"]["facility_address"]}`
            : `${getEndTime()}`}
        </TextVariant2>
      </Div>
    </Div>
  );
};

export default SelectedOffice;

import React, { useEffect, useState } from "react";
import TopNav from "../../TopNav";
import { Button, Div, Text } from "../../StyledComponents";
import { NumCount, SubTitle, TextArea } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import {
  GetFeedback,
  RefreshFeedback,
} from "../../../StateManagement/Reducers/SettingState";
import { toast } from "react-toastify";
import Error from "../../StyledComponents/Toastify/Error";
import { globalColor, hoverColor } from "../../utils/RepeatFunctions/Schedule";

const FeedBack = () => {
  const dispatch = useDispatch();

  const Refresh = useSelector((state) => state.Setting.RefreshFeedback);
  const [comment, setComment] = useState("");
  const [commentErr, setCommentErr] = useState(false);
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

  const handleComment = (e) => {
    setComment(e.target.value);
    setCommentErr(false);
  };

  const handleKeyPress = (e) => {
    if (e.key == " ") {
      if (comment.endsWith(" ") || comment.length == 0) {
        e.preventDefault();
      }
    }
  };
  const handleSubmit = () => {
    if (comment.length >= 20) {
      dispatch(GetFeedback({ pid: Pat_ID, feedback: comment }));
    } else {
      setCommentErr(true);
    }
  };

  useEffect(() => {
    if (comment.length >= 500) {
      toast(<Error msg="Maximum character limit (500) reached!" />, {
        containerId: "B",
        className: "error_badge",
      });
    }
  }, [comment]);
  useEffect(() => {
    if (Refresh === 1) {
      setComment("");
      dispatch(RefreshFeedback(""));
    }
  }, [Refresh]);
  return (
    <>
      <TopNav title="Feedback" />
      <Div margin="20px 40px 40px 40px">
        <SubTitle>Comment your Feedback</SubTitle>

        <TextArea
          border={
            commentErr
              ? "1px solid #F24822"
              : "1px solid rgba(46, 46, 46, 0.25)"
          }
          maxLength={500}
          placeholder="Write your comments here"
          onChange={handleComment}
          onKeyPress={handleKeyPress}
          value={comment}
        ></TextArea>

        {commentErr && (
          <Text
            textAlign="left"
            color="#F24822"
            fontSize="10px"
            fontWeight="500"
            mb="0px"
            mt="0px"
          >
            Feedback should be Minimum 20 characters
          </Text>
        )}
        <NumCount>{comment.length}/500</NumCount>
        <Button
          background={globalColor}
          hoverBackColor={hoverColor}
          className="save_active"
          display="flex"
          padding="9px 25px"
          alignItem="center"
          borderRadius="4px"
          marginTop="23px"
          onClick={handleSubmit}
        >
          Submit Feedback
        </Button>
      </Div>
    </>
  );
};

export default FeedBack;

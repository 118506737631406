import { FaPhoneAlt } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import { IoMdMail } from "react-icons/io";
import { GrDown } from "react-icons/gr";
import { globalColor, globalFont } from "../utils/RepeatFunctions/Schedule";

export const Phone = styled(FaPhoneAlt)`
  font-size: 24px;
  color: ${globalColor};
`;

export const Mail = styled(IoMdMail)`
  font-size: 26px;
  color: ${globalColor};
`;

export const DownArrow = styled(GrDown)`
  font-size: 20px;
  color: rgba(46, 46, 46, 0.75);
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 500px; /* Adjust max-height as needed */
  }
`;

export const AnswerText = styled.div`
  opacity: ${(props) => (props.drop ? "1" : "0")};
  max-height: ${(props) => (props.drop ? "500px" : "0")};
  overflow: hidden;
  display: ${(props) => (props.drop ? "block" : "none")};
  color: ${(p) => (p.color ? p.color : "black")};
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : globalFont)};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.align && p.align};
  padding: ${(p) => p.padding && p.padding};
  animation: ${fadeInOut} 0.3s ease;
`;

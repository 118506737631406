import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getInsertDocumentslist } from "../../../StateManagement/Reducers/DocumentsState";

import {
  ModalTitle,
  Modal,
  ModalContainer,
  Div,
  Text,
  Image,
  Button,
} from "../../StyledComponents";
import {
  Childdiv,
  ModalDialog,
  DragDrop,
  FileInput,
  Parentdiv,
  ProgressLabel,
  Docdiv,
} from "./Styles";

import { BsXLg } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import UploadFile from "../../../Assets/images/uploadDoc.png";
import pdfIcon from "../../../Assets//images/EllipsePdf.png";
import Unsupporteddocument from "../../../Assets/images/Unsupporteddocument.png";
import {
  globalColor,
  globalFont,
  hoverColor,
} from "../../utils/RepeatFunctions/Schedule";

const UploadDocument = ({ show, close, setdocumenthead }) => {
  // ------------------------------ State And Variables Start------------------------ //

  const dispatch = useDispatch();

  const [files, setFiles] = useState(null);
  const hiddenFileInput = useRef(null);
  const [fileIs, setFileIs] = useState(false);
  const [ModalAnimat] = useState(true);
  // const [uploadIs, setUploadIs] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);
  const [unspErr, setUnspErr] = useState(false);

  const Refresh = useSelector((state) => state.Document.Refresh);

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const HandleClick = () => {
    hiddenFileInput.current.click();
  };

  const HandleChange = (event) => {
    const fileUploaded = event.target.files;
    setFiles([]);
    setFileIs(true);
    for (let i = 0; i < fileUploaded.length; i++) {
      if (fileUploaded[i].size < 10e6) {
        if (
          fileUploaded[i].type === "image/jpeg" ||
          fileUploaded[i].type === "image/png" ||
          fileUploaded[i].type === "application/pdf"
        ) {
          setUnspErr(false);
          let temp = {
            FileName: fileUploaded[i]["name"],
            File: fileUploaded[i],
          };
          setFiles((item) => [...item, temp]);
        } else {
          setUnspErr(true);
        }
      }
    }
  };
  const ClearFile = (value) => {
    setFiles((prevState) => prevState.filter((prevItem) => prevItem !== value));
  };

  const HandleClose = () => {
    close();
    setFiles(null);
    hiddenFileInput.current.value = null;
  };

  const UploadDocument = () => {
    if (files) {
      setbtnDisable(true);
      const documentsData = new FormData();
      for (var x = 0; x < files.length; x++) {
        documentsData.append("fileupload[]", files[x].File);
      }
      dispatch(getInsertDocumentslist(documentsData));
      // setUploadIs(true);
      setdocumenthead("All Documents");
    }
  };

  // ------------------------------Functions End---------------------------- //

  // ------------------------------UseEffect Start-------------------------- //

  useEffect(() => {
    if (Refresh === "1") HandleClose();
  }, [Refresh]);

  // ------------------------------UseEffect End-------------------------- //

  return (
    <Modal width="100%" height="100%" show={show}>
      <ModalContainer justifyContent="center">
        <ModalDialog animation={ModalAnimat}>
          <Div
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ModalTitle
              lineHight="27px"
              fontSize="20px"
              fontWeight="600"
              color="#0a1629"
            >
              Upload Documents
            </ModalTitle>

            <BsXLg
              style={{
                color: globalColor,
                cursor: "pointer",
                height: "14px",
                width: "14px",
              }}
              onClick={HandleClose}
            />
          </Div>
          <DragDrop>
            <FileInput
              type="file"
              ref={hiddenFileInput}
              onChange={HandleChange}
              style={{
                paddingBottom: "56%",
                paddingLeft: "39%",
                position: "absolute",
                marginLeft: "-52%",
                opacity: 0,
              }}
              accept=".png, .pdf, .jpg, .jpeg"
            />
            <Text
              fontSize="16px"
              lineHeight=" 22px"
              color="#263446"
              marginBottom="20px"
              mt="20px"
            >
              Drag & Drop
            </Text>
            <Image
              loading="lazy"
              width="45px"
              margin="0px auto 25px auto"
              src={UploadFile}
            />
            <Text fontSize="12px" color="#7d8592" marginBottom="20px">
              Choose .JPG, .JPEG, .PNG or .PDF File Smaller than 10mb
            </Text>
            <Button
              height="40px"
              background="#ffffff"
              border={`1px solid ${globalColor}`}
              className="close_active"
              hoverBackColor={hoverColor}
              borderRadius="4px"
              cursor="pointer"
              fontFamily={globalFont}
              fontWeight="500"
              fontSize="16px"
              lineHeight=" 16px"
              color={globalColor}
              padding="12px 20px"
              marginLeft="auto"
              marginRight="auto"
              marginBottom="20px"
              onClick={HandleClick}
            >
              Choose File
            </Button>
          </DragDrop>

          {files && (
            <>
              <Div
                display="flex"
                justifyContent="space-between"
                margin="20px 0px 8px 0px"
              >
                {fileIs ? (
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="19px"
                    color="#464646"
                    mt="0px"
                    mb="0px"
                    textAlign="start"
                  >
                    {files.length} of {files.length} Uploaded
                  </Text>
                ) : (
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="19px"
                    mt="0px"
                    mb="0px"
                    color="#464646"
                    textAlign="start"
                  >
                    Done
                  </Text>
                )}

                {fileIs && (
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="19px"
                    color="#464646"
                    cursor="pointer"
                    mt="0px"
                    mb="0px"
                    textAlign="end"
                    onClick={() => setFiles(null)}
                  >
                    Cancel
                  </Text>
                )}
              </Div>
              {unspErr && (
                <Docdiv>
                  <Childdiv>
                    <Image
                      loading="lazy"
                      width="32px"
                      height="32px"
                      margin="8px 12px"
                      src={Unsupporteddocument}
                    />
                    <Text
                      MaxWidth="200px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      fontFamily={globalFont}
                      color="rgba(242, 72, 34, 1)"
                      fontSize="14px"
                      lineHeight="17px"
                      display="flex"
                      alignItems="center"
                    >
                      Unsupported File Format
                    </Text>
                  </Childdiv>
                </Docdiv>
              )}
              {files.map((val, ind) => (
                <Parentdiv key={ind}>
                  <Childdiv>
                    <Image
                      loading="lazy"
                      width="32px"
                      height="32px"
                      margin="8px 12px"
                      src={pdfIcon}
                    />
                    <ProgressLabel file={files ? true : false}>
                      <Text
                        MaxWidth="200px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        fontFamily={globalFont}
                        color={!files ? `#fafafa` : globalColor}
                        fontSize="14px"
                        lineHeight="17px"
                      >
                        {val.FileName}
                      </Text>
                    </ProgressLabel>
                  </Childdiv>
                  <AiOutlineCloseCircle
                    style={{
                      marginTop: "5px",
                      cursor: "pointer",
                      marginRight: "10px",
                      height: "20px",
                      width: "20px",
                    }}
                    onClick={() => ClearFile(val)}
                  />
                </Parentdiv>
              ))}
              <Div flexCenterVertical={true} justifyContent="center">
                <Button
                  background={globalColor}
                  hoverBackColor={hoverColor}
                  borderRadius="8px"
                  fontFamily={globalFont}
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="16px"
                  color="#ffffff"
                  padding="12px 20px"
                  marginTop="20px"
                  cursor="pointer"
                  onClick={UploadDocument}
                  disabled={btnDisable}
                >
                  Upload File
                </Button>
              </Div>
            </>
          )}
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default UploadDocument;

import styled, { keyframes } from "styled-components";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

export const Curcle = styled.div`
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60%;
  height: calc(100vw / 3.25);
  min-width: 228px;
  min-height: 228px;
  transform: translate(-50%, -50%);
  background: ${globalColor};
`;

export const CurcleMiddle = styled.div`
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  height: calc(100vw / 2.5);
  min-width: 368px;
  min-height: 368px;
  transform: translate(-50%, -50%);
  background: rgba(64, 123, 255, 0.1);
`;

export const CurcleStart = styled.div`
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: calc(100vw / 2);
  min-width: 512px;
  min-height: 512px;
  transform: translate(-50%, -50%);
  background: rgba(64, 123, 255, 0.05);
`;

const slide = keyframes`
0% {
  right: -25%;
  transition: opacity 3s linear, right 0.7s ease-out;
}
100% {
  right: 0%;
}
`;

const slideIpad = keyframes`
  0% {
    right: -12%;
  }
`;

export const CarouselContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  right: 0;
  justify-content: center;
  place-items: center;
  animation: ${slide} 2s ease-out;

  @media (max-width: 820px) {
    animation: ${slideIpad} 2s ease-out;
  }
`;

export const LabelContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid black;
`;

export const TextLabel = styled.p`
  position: absolute;
  text-align: ${(props) => props.center && props.center};
  text-align: center;
  bottom: 77px;
  padding: 0 10%;
`;

export const ActiveSlideContainer = styled.div`
  display: flex;
  gap: 12px;
  position: absolute;
  bottom: 30px;
  justify-content: center;
  width: 100%;
`;

export const ActiveSlide = styled.div`
  width: 138px;
  height: 6px;
  border-radius: 3px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? globalColor : "#CCD6E1")};
  transition: 1s;
`;

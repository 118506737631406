import React from "react";
import { useSelector } from "react-redux";

import { IoCloseSharp } from "react-icons/io5";
import { ModalDailog, ModalBody } from "./StyledComponents";
import {
  NormalImage,
  Modal,
  ModalContainer,
  Text,
} from "../../StyledComponents";

import savedIcon from "../../../Assets/images/saved_successfully.png";
import { globalFont } from "../../utils/RepeatFunctions/Schedule";

const MessageModal = ({ active, color, onHide }) => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );

  // ------------------------------ State and Variables End--------------------------------------------------- //

  return (
    <>
      <Modal show={active} width="100%" height="100%">
        <ModalContainer justifyContent="center">
          <ModalDailog onClick={onHide} show={active}>
            <ModalBody color={color}>
              {color === "success" ? (
                <NormalImage
                  loading="lazy"
                  src={savedIcon}
                  height="128px"
                  width="128px"
                />
              ) : color === "danger" ? (
                <IoCloseSharp />
              ) : (
                <></>
              )}
              <Text
                fontWeight="600"
                fontFamily={globalFont}
                color="#2E2C34"
                lineHeight="21px"
                margin="8px 0px"
                whiteSpace="pre-line"
              >
                {appointmentData["message"]}
              </Text>
            </ModalBody>
          </ModalDailog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default MessageModal;

import styled from "styled-components";
import { Button, Div } from "../../StyledComponents";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

export const ModalCntDiv = styled(Div)`
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
`;

export const Btnfields = styled(Div)`
  justify-content: flex-end;
`;

export const RmModButton = styled(Button)`
  width: 110px;
  height: 32px;
  border-radius: 6px;
  font-weight: 500;
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: ${globalColor};
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: ${globalColor};
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
`;

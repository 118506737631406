import styled from "styled-components";
import { globalColor } from "../utils/RepeatFunctions/Schedule";

export const Container1 = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  padding: ${(p) => p.padding && p.padding};
`;
export const TabPanel = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding: ${(p) => p.padding && p.padding};
`;
export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "18px")};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? globalColor : "#8D98AF")};
  cursor: pointer;
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 76px;
                        height: 2px;
                        background: ${globalColor};
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 77px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(0px);
                          width: 76px;
                        }
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 77px;
                        height: 2px;
                        background: ${globalColor};
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(97px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(98px);
                          width: 73px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(98px);
                          width: 73px;
                        }
                         }
               `;
    }
  }}
`;

// my profile styles//

export const Hrline = styled.hr`
  border: 1px solid #d8e0f0;
  margin-top: ${(props) => (props.mt ? props.mt : "20px")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "20px")};
  margin-left: ${(p) => (p.mmarginLeft ? p.marginLeft : "-40px")};
  border-bottom: none;
`;

export const Imagecalendar = styled.img`
  position: absolute;
  object-fit: contain;
  cursor: pointer;
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  left: ${(p) => p.left && p.left};
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 3px;
`;

/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import {
  ContainerCenter,
  FlexTitle,
  FrameContainer,
  IFrame,
  Image,
  TitleLabel,
} from "./Styles";
import Close from "../../Assets/images/CloseDoc.png";
import { useEffect } from "react";
import { GetUpdateWatched } from "../../StateManagement/Reducers/HomeWorkState";
import { useDispatch } from "react-redux";
import { memo } from "react";
import { globalFont } from "../utils/RepeatFunctions/Schedule";

const DocumentView = ({ url, docTitle, id, clearId, setMyState, myState }) => {
  const B_Url =
    process.env.REACT_APP_BACKEND_FILE_ACCESS +
    "/sites/default/Education_Files/" +
    url;
  const dispatch = useDispatch(id);
  useEffect(() => {
    if (id) {
      dispatch(GetUpdateWatched(parseInt(id, 10)));
    }
    return () => {
      clearId(null);
    };
  }, [id]);
  return (
    myState.showdoc && (
      <ContainerCenter>
        <FlexTitle border>
          <Image
            src={Close}
            cursor="pointer"
            onClick={() => setMyState({ ...myState, showdoc: false })}
          />
          <TitleLabel
            fontFamily={globalFont}
            fontSize="24px"
            fontHeight="29px"
            margin
          >
            {docTitle}
          </TitleLabel>
        </FlexTitle>
        <FrameContainer>
          <IFrame src={B_Url} loading="eager" height="84%" />
        </FrameContainer>
      </ContainerCenter>
    )
  );
};

export default memo(DocumentView);

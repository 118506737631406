import styled from "styled-components";
import {
  globalColor,
  globalFont,
  hoverColor,
} from "../../../Modules/utils/RepeatFunctions/Schedule";
export const ModalDialog = styled.div`
  padding: 24px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  width: 398px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;

export const TextBox = styled.input`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
  width: 350px;
  height: 48px;
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 4px;
  border-radius: 4px;
  background: rgba(64, 123, 255, 0.1);
  height: 87px;
  padding: 20px;
`;

export const TextArea = styled.input`
  border-radius: 4px;
  background: rgba(64, 123, 255, 0);
  border: none;
  outline: none;
  resize: none;
  text-align: center;
  width: 100%;
  font-family: ${globalFont};
  font-size: 1.8em;
`;

export const CancelButton = styled.button`
  background: #fff;
  border: 1px solid ${globalColor};
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: ${globalColor};
  width: 96px;
  :hover {
    background: rgba(244, 246, 249, 0.75);
    color: ${globalColor};
  }

  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    background-color: #fff;
    cursor: not-allowed;
  }
`;

export const SaveButton = styled.button`
  background: ${globalColor};
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 4px;
  border: 1px solid ${globalColor};
  cursor: pointer;
  padding: 8px 25px;
  margin-left: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.0025em;
  color: #fff;
  width: 96px;
  :hover {
    background: ${hoverColor};
    color: #ffffff;
  }
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: ${globalColor};
    cursor: not-allowed;
  }
`;

import styled from "styled-components";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

export const MessageContainer = styled.div`
  width: 100%;
  height: calc(100% - 167px);
  padding: 24px 40px 24px 27px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  border-size: box-sizing;
  border: ${({ drop }) => (drop ? "dotted 2px #8DA3F0" : "")};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ChatBox = styled.div`
  align-self: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  margin-bottom: 18px;
  max-width: ${({ type }) =>
    type === "date" ? "175px" : type === "text" ? "100%" : "fit-content"};
  text-align: ${({ type }) => (type === "date" ? "center" : "left")};
  display: flex;
  flex-direction: row;
`;

export const ChatBoxMsg = styled.div`
  background: ${({ user }) => (user ? globalColor : "#ffffff")};
  max-width: ${({ user }) => user && "93%"};
  color: ${({ user }) => (user ? " #FFFFFF" : "#8A969F")};
  border-radius: ${({ type }) => (type === "date" ? "16px" : "8px")};
  padding: ${({ type }) => (type === "date" ? "6px 20px" : "4px 8px")};
  margin-right: ${({ user }) => user && "12px"};
  // display: ${({ user }) => user && "flex"};

  position: relative;
  min-height: 20px;
  ${({ user }) =>
    user &&
    `
&::after {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-left-color: ${globalColor};
  border-right: 0;
  right: -6px;
  top: 22px;
  margin-top: -19px;
}
`};
  ${({ user, type }) =>
    !user &&
    type !== "date" &&
    `
&::before {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-right-color: #ffffff;
  border-left: 0;
  left: 11px;
  top: 8px;
  margin-left: -19px;
}
`};
`;

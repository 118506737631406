import styled from "styled-components";
import { globalFont } from "../../utils/RepeatFunctions/Schedule";

export const SubTitle = styled.h6`
  font-size: 18px;
  font-weight: 700;
  color: #232934;
`;
export const TextArea = styled.textarea`
  border: ${(p) => p.border && p.border};
  width: 100%;
  resize: none;
  border-radius: 4px;
  height: 200px;
  max-width: 940px;
  padding: 10px;
  margin-top: 8px;
  outline: none;
  ::-webkit-scrollbar {
    width: 3px;
  }
`;

export const NumCount = styled.h6`
  font-size: 12px;
  color: rgba(46, 46, 46, 0.5);
  max-width: 940px;
  text-align: right;
  font-weight: 400;
  font-family: ${globalFont};
`;

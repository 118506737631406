import React, { useState } from "react";
import TopNav from "../TopNav";
import { Div, Text } from "../StyledComponents";
import { AnswerText, DownArrow, DropdownContent, Mail, Phone } from "./Styles";
import { globalColor } from "../utils/RepeatFunctions/Schedule";

const HelpSupport = () => {
  const [drop, setDrop] = useState(false);

  const [questions, setQuestions] = useState([
    {
      question: "How do I reset my password for the patient portal app?",
      answer: `You can reset your password by selecting the "Forgot Password" option on the login screen. Follow the prompts to verify your identity and create a new password`,
      isOpen: false,
    },

    {
      question:
        "How can I schedule an appointment using the patient portal app?",
      answer: `Look for the "Schedule Appointment" feature within the app. Choose a convenient time slot, select your healthcare provider, and confirm the appointment.`,
      isOpen: false,
    },

    {
      question: "Can I pay my medical bills through the patient portal app?",
      answer: `Yes, Our patient portal app provides a secure payment feature. Look for the billing or payment section to settle outstanding medical bills.`,
      isOpen: false,
    },
    {
      question: "How do I update my contact/other information on the app?",
      answer: `Navigate to the settings or profile section of the app to update your contact/other information, including address, phone number, and email.`,
      isOpen: false,
    },
    {
      question: "Can I message my healthcare provider through the app?",
      answer: `Yes, our app typically includes a secure messaging feature that allows you to communicate with your healthcare provider. Look for the messaging or inbox section.`,
      isOpen: false,
    },
    {
      question:
        "What should I do if I encounter technical issues with the app?",
      answer: `If you experience technical issues, try restarting the app. If the problem persists, contact our support team/helpline for assistance.`,
      isOpen: false,
    },
    {
      question: "How do I sign up for an account on the patient portal app?",
      answer: `To sign up, download the app and select the "Sign Up" or "Create Account" option. Follow the registration prompts, providing the required information to create your account.`,
      isOpen: false,
    },
    {
      question:
        "Can I request a virtual visit or telehealth appointment through the app?",
      answer: `Yes, If the provider is providing a telehealth Appointment, the patient can request the telehealth Appointment. If not, then the patient can request a Direct visit.`,
      isOpen: false,
    },
    {
      question:
        "What do I do if I encounter issues with insurance information on the patient portal app?",
      answer: `If you experience issues with your insurance information, first, double-check the details you've entered. If the problem persists, contact the app's support team or the healthcare provider's billing department for assistance. They can help resolve any discrepancies or update your information as needed.`,
      isOpen: false,
    },
    {
      question: "Is the patient portal mobile app secure?",
      answer: `Yes, the app uses advanced security measures such as encryption to protect your health information. Set a strong password and keep it confidential for added security.`,
      isOpen: false,
    },
  ]);
  const toggleAnswer = (index) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
      return updatedQuestions;
    });
  };

  return (
    <>
      <TopNav title="Help & Support" />

      <Div padding="20px 40px 0px 40px">
        <Div>
          <Text
            textAlign="left"
            color="#232934"
            fontSize="18px"
            fontWeight="600"
          >
            Contact Details
          </Text>
          <Div display="flex" marginTop="8px">
            <Div
              width="50%"
              border="1px solid rgba(46, 46, 46, 0.10)"
              marginRight="30px"
              padding="16px 0 15px 16px"
              display="flex"
              justifyContent="flex-start"
              borderRadius="4px"
            >
              <Div
                borderRadius="50%"
                background="rgba(46, 46, 46, 0.05)"
                marginRight="16px"
                padding="12px"
                display="flex"
                alignItems="center"
              >
                <Phone />
              </Div>
              <Div>
                <Text
                  color="#2E2E2E"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="normal"
                  marginBottom="8px"
                >
                  Our Helpline Number
                </Text>
                <Text
                  display="flex"
                  color={globalColor}
                  fontSize="14px"
                  fontWeight="600"
                >
                  +1 (571) 213 3245
                </Text>
              </Div>
            </Div>
            <Div
              width="50%"
              border="1px solid rgba(46, 46, 46, 0.10)"
              padding="16px 0 15px 16px"
              display="flex"
              justifyContent="flex-start"
            >
              <Div
                borderRadius="50%"
                background="rgba(46, 46, 46, 0.05)"
                marginRight="16px"
                padding="12px"
                display="flex"
                alignItems="center"
              >
                <Mail />
              </Div>
              <Div>
                <Text
                  color="#2E2E2E"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="normal"
                  marginBottom="8px"
                >
                  Sales & Support Mail ID
                </Text>
                <Text
                  display="flex"
                  color={globalColor}
                  fontSize="14px"
                  fontWeight="600"
                >
                  careteam@vozo.xyz
                </Text>
              </Div>
            </Div>
          </Div>
        </Div>
        <Div marginTop="40px">
          <Text
            color="#232934"
            fontWeight="600"
            fontSize="18px"
            textAlign="left"
            marginBottom="16px"
          >
            Frequently Asked Questions
          </Text>
          {questions.map((q, index) => (
            <Div
              key={index}
              marginBottom="16px"
              borderRadius=" 4px"
              border="1px solid rgba(46, 46, 46, 0.10)"
              padding="16px 20px 15px 20px"
              display="flex"
              flexDirection="column"
            >
              <Div
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text
                  display="flex"
                  color="rgba(46, 46, 46, 1)"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="normal"
                >
                  {q.question}
                </Text>
                <DownArrow onClick={() => toggleAnswer(index)} />
              </Div>
              <AnswerText
                drop={q.isOpen}
                padding="16px 100px 7px 0px"
                textAlign="left"
                color="rgba(46, 46, 46, 0.75)"
                fontSize="16px"
                fontWeight="400"
              >
                {q.answer}
              </AnswerText>
            </Div>
          ))}
        </Div>
      </Div>
    </>
  );
};

export default HelpSupport;

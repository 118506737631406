import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { BsXLg, BsDownload } from "react-icons/bs";
import Loading from "../../../StyledComponents/Loading";
import { useReactToPrint } from "react-to-print";
import GeneratePdf from "./reportGenerator";
import { AiOutlinePrinter } from "react-icons/ai";
import {
  Modal,
  ModalContainer,
  ModalHeader,
  SpanCont,
  Div,
  ModalBody,
  Image,
  PageTitle,
  Text,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Button,
} from "../../../StyledComponents";
import { ModalDiag, PaidStatus } from "../styles";
import MakePayment from "../../../StyledComponents/MakePayment";
import StripeAlert from "../../../StyledComponents/StripeAlert";
import moment from "moment";
import { globalFont } from "../../../utils/RepeatFunctions/Schedule";

const InvoicePopup = (props) => {
  const componentRef = useRef();
  const [MakePay, setMakePay] = useState(false);
  const [OneInvoiceList, setOneInvoiceList] = useState([]);
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }
  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const InvoiceData = useSelector((state) => state.Billing.singleInvoice);
  const Loadings = useSelector((state) => state.Billing.Loading);
  const Ext = useSelector((state) => state.Billing.ext);
  const Base = useSelector((state) => state.Billing.base64);
  const statelogo = useSelector((state) => state.Billing.stateLogo);
  const symbol = useSelector((state) => state.Billing.symbol);
  const [cardDetails, setCardDetails] = useState([]);
  const stripeAllowed = useSelector((state) => state.Billing.stripeAllowed);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const handleDownload = () => {
    GeneratePdf(
      InvoiceData,
      props.Data.invoice_id,
      props.Data.invoice_date,
      props.Data.providerName,
      props.Data.facilityname,
      props.Data.ProviderStreet,
      props.Data.ProviderCity,
      props.Data.ProviderState,
      props.Data.ProviderTaxid,
      props.Data.ProviderEmail,
      props.Data.PatName,
      props.Data.PatCell,
      props.Data.PatStreet,
      props.Data.PatCity,
      props.Data.PatState,
      props.Data.Patzip,
      props.Data.PatEmail,
      props.Data.total_amount,
      props.Data.total_balance,
      Ext,
      Base,
      statelogo,
      symbol
    );
  };
  const dateFormat1 = moment(
    localStorage.getItem("dateFormat") ? localStorage.getItem("dateFormat") : ""
  );
  const dateformat = dateFormat1._i;
  const dtFormatsec = `${dateformat} hh:mm A`;

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePayment = () => {
    if (stripeAllowed === false) {
      setModalAlerShow(true);
    } else {
      setMakePay(true);
    }
  };

  const handleModalClick = () => {
    setModalAlerShow(false);
  };

  useEffect(() => {
    let a = [];
    a.push(props.Data);
    setOneInvoiceList(a);
    setCardDetails(props.cardDetails);
  }, [props, InvoiceData]);

  return (
    <>
      <Modal show={props.show}>
        {Loadings ? (
          <Loading height="100vh" />
        ) : (
          <ModalContainer justifyContent="center">
            <ModalDiag animation={true}>
              <ModalHeader borderLess>
                <Div
                  justifyContent="space-between"
                  display="flex"
                  padding="12px"
                  width="100%"
                  height="100%"
                  boxShadow="0px 1px 8px rgba(20, 46, 110, 0.1)"
                >
                  <Div padding="16px">
                    <BsXLg
                      style={{
                        color: "#718096",
                        cursor: "pointer",
                        height: "16px",
                        width: "16px",
                      }}
                      onClick={() => props.onHide()}
                      id="bil_inv-lst_cls"
                    />
                    <SpanCont
                      fontFamily={globalFont}
                      color="#2D3748;"
                      fontWeight="600"
                      fontSize="20px"
                      lineHeight="24.2px"
                      marginLeft="36px"
                    >
                      Invoice for&nbsp;{props.Data.PatName}
                    </SpanCont>
                  </Div>

                  <Div display="flex">
                    <Div
                      alignItems="center"
                      display="flex"
                      style={{ borderRight: "1px solid #EBEAED" }}
                    >
                      <AiOutlinePrinter
                        style={{
                          color: "#718096",
                          cursor: "pointer",
                          height: "24px",
                          width: "24px",
                          // marginTop: "17px",
                          marginRight: "20px",
                        }}
                        onClick={HandlePrint}
                      />
                      <BsDownload
                        style={{
                          color: "#718096",
                          cursor: "pointer",
                          height: "24px",
                          width: "24px",
                          // marginTop: "17px",
                          marginRight: "20px",
                        }}
                        onClick={handleDownload}
                      />
                    </Div>
                    <Div
                      marginLeft="20px"
                      alignItems="center"
                      display="flex"
                      marginRight="28px"
                    >
                      <Button
                        color="#ffffff"
                        background="#26BF71"
                        border="1px solid #26BF71"
                        borderRadius="4px"
                        padding="7px 11.5px"
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16px"
                        fontFamily={globalFont}
                        onClick={handlePayment}
                        disabled={props.Data.total_balance === "0.00" && true}
                      >
                        Add Payment
                      </Button>
                    </Div>
                  </Div>
                </Div>
              </ModalHeader>
              <ModalBody backgroundColor="#f7f7f7" padding="26px 0px">
                <Div
                  backColor="#ffff"
                  boxShadow=" 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05)"
                  borderRadius="6px"
                  width="800px"
                  margin="0px auto"
                  ref={componentRef}
                >
                  <Div
                    backColor="#f7f7f7"
                    padding="24px 40px 25px 40px"
                    display="flex"
                    justifyContent={statelogo !== "0" && "space-between"}
                    alignItems="center"
                  >
                    {statelogo !== "0" && (
                      <Image
                        loading="lazy"
                        id="canvasportal"
                        width="50px"
                        src={File_URL + Ext}
                      />
                    )}
                    {props.Data.total_balance === "0.00" && (
                      <PaidStatus>PAID</PaidStatus>
                    )}
                    <Div
                      display="block"
                      backColor="none"
                      margin={!(statelogo !== "0") && "0px 0px 0px auto"}
                    >
                      <PageTitle
                        fontWeight="500"
                        fontSize="22px"
                        lineHeight="26.63px"
                        fontFamily={globalFont}
                      >
                        Invoice
                      </PageTitle>
                      <Div
                        display="flex"
                        backColor="none"
                        margin="16px 0px 0px 0px"
                      >
                        <Div
                          borderRight=" 1px solid #000000"
                          backColor="none"
                          padding="0px 10px 0px 0px"
                        >
                          <Text
                            textAlign="left"
                            mb="16px"
                            fontWeight="400"
                            fontSize="16px"
                            lineHeight="19.36px"
                            mt="0px"
                            fontFamily={globalFont}
                          >
                            Invoice Number
                          </Text>
                          <Text
                            textAlign="left"
                            fontWeight="400"
                            fontSize="16px"
                            lineHeight="19.36px"
                            fontFamily={globalFont}
                            mt="0px"
                            mb="0px"
                          >
                            Issue Date
                          </Text>
                        </Div>
                        <Div backColor="none" padding="0px 0px 0px 10px">
                          <Text
                            textAlign="left"
                            mb="16px"
                            fontWeight="400"
                            fontSize="16px"
                            lineHeight="19.36px"
                            mt="0px"
                            fontFamily={globalFont}
                          >
                            {props.Data.invoice_id}
                          </Text>
                          <Text
                            textAlign="left"
                            fontWeight="400"
                            fontSize="16px"
                            lineHeight="19.36px"
                            mt="0px"
                            mb="0px"
                            fontFamily={globalFont}
                          >
                            {moment(props.Data.invoice_date).format(dateformat)}
                          </Text>
                        </Div>
                      </Div>
                    </Div>
                  </Div>

                  <Div
                    padding="24px 40px 36px 40px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Div>
                      <PageTitle
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontFamily={globalFont}
                      >
                        Bill From
                      </PageTitle>

                      <Text
                        textAlign="left"
                        mb="8px"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="7px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.facilityname}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        mb="8px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.ProviderStreet}&nbsp;
                        {props.Data.ProviderCity}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        fontFamily={globalFont}
                        mb="0px"
                        color="#718096"
                      >
                        {props.Data.ProviderState}
                      </Text>
                    </Div>
                    <Div width="232.3px">
                      <PageTitle
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontFamily={globalFont}
                      >
                        Bill to
                      </PageTitle>

                      <Text
                        textAlign="left"
                        mb="8px"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="7px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.PatName}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        mb="8px"
                        color="#718096"
                        fontFamily={globalFont}
                      >
                        {props.Data.PatStreet}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        mb="8px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.PatCity}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        mb="0px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.PatState} &nbsp; {props.Data.Patzip}
                      </Text>
                    </Div>
                  </Div>

                  <Div
                    padding="0px 40px 37px 40px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Div>
                      <PageTitle
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontFamily={globalFont}
                      >
                        Provider
                      </PageTitle>

                      <Text
                        textAlign="left"
                        mb="8px"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="7px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.providerName}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        mb="8px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        Tax ID : {props.Data.ProviderTaxid}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        fontFamily={globalFont}
                        mb="0px"
                        color="#718096"
                      >
                        {props.Data.ProviderEmail}
                      </Text>
                    </Div>
                    <Div width="232.3px">
                      <PageTitle
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontFamily={globalFont}
                      >
                        Client
                      </PageTitle>

                      <Text
                        textAlign="left"
                        mb="8px"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="7px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.PatName}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        mb="8px"
                        color="#718096"
                        fontFamily={globalFont}
                      >
                        {props.Data.PatCell}
                      </Text>
                      <Text
                        textAlign="left"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.94px"
                        mt="0px"
                        mb="0px"
                        fontFamily={globalFont}
                        color="#718096"
                      >
                        {props.Data.PatEmail}
                      </Text>
                    </Div>
                  </Div>

                  <Table padding="0px 0px 20px 0px">
                    <TableHead
                      background="#FAFAFA"
                      scroll={InvoiceData.length > 2 ? true : false}
                    >
                      <TableRow>
                        <TableHeadCell
                          fontFamily={globalFont}
                          fontWeight="500"
                          lineHeight="16.94px"
                          fontSize="14px"
                        >
                          Date
                        </TableHeadCell>
                        <TableHeadCell
                          fontFamily={globalFont}
                          fontWeight="500"
                          lineHeight="16.94px"
                          fontSize="14px"
                        >
                          Description
                        </TableHeadCell>

                        <TableHeadCell
                          fontFamily={globalFont}
                          fontWeight="500"
                          lineHeight="16.94px"
                          fontSize="14px"
                        >
                          Balance
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      scroll={InvoiceData.length > 2 ? true : false}
                      height={InvoiceData.length > 2 ? "120px" : ""}
                    >
                      {InvoiceData.map((row, i) => (
                        <TableRow
                          key={i}
                          scroll={InvoiceData.length > 2 ? true : false}
                          bbtom={true}
                        >
                          <TableBodyCell
                            fontFamily={globalFont}
                            fontWeight="400"
                            lineHeight="16.94px"
                            fontSize="14px"
                            color=" #2D3748"
                            padding="16px 40px 16px 40px"
                            bodered
                          >
                            {moment(row.date).format(dtFormatsec)}
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily={globalFont}
                            fontWeight="400"
                            lineHeight="16.94px"
                            fontSize="14px"
                            color=" #2D3748"
                            padding="16px 40px 16px 40px"
                            bodered
                          >
                            {row.serviceName}
                          </TableBodyCell>

                          <TableBodyCell
                            fontFamily={globalFont}
                            fontWeight="400"
                            lineHeight="16.94px"
                            fontSize="14px"
                            color=" #2D3748"
                            bodered
                            padding={
                              InvoiceData.length > 2
                                ? "16px 40px 16px 43px"
                                : "16px 40px 16px 40px"
                            }
                          >
                            {symbol}
                            {row.fee}
                          </TableBodyCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Div
                    display="flex"
                    justifyContent="flex-end"
                    padding="24px 20px 16px 20px"
                    margin="0px 40px 0px 20px"
                  >
                    <SpanCont
                      lineHeight="19.36px"
                      fontWeight="400"
                      fontFamily={globalFont}
                      color="#2D3748"
                      width="80%"
                      textAlign="right"
                    >
                      Total:
                    </SpanCont>
                    <SpanCont
                      fontSize="16px"
                      lineHeight="19.36px"
                      fontWeight="500"
                      fontFamily={globalFont}
                      color=" #000000"
                      width="15%"
                      textAlign="right"
                    >
                      {symbol}
                      {props.Data.total_amount}
                    </SpanCont>
                  </Div>
                  <Div
                    display="flex"
                    justifyContent="flex-end"
                    padding="0px 20px 132px 20px"
                    margin="0px 40px 20px 20px"
                  >
                    <SpanCont
                      fontSize="16px"
                      lineHeight="19.36px"
                      fontFamily={globalFont}
                      color="#2D3748"
                      width="80%"
                      textAlign="right"
                    >
                      Make Payments to:
                    </SpanCont>
                    <SpanCont
                      fontWeight="400"
                      fontSize="16px"
                      lineHeight="19.36px"
                      fontFamily={globalFont}
                      color="#2D3748"
                      width="15%"
                      textAlign="right"
                    >
                      {props.Data.facilityname}
                    </SpanCont>
                  </Div>
                </Div>
              </ModalBody>
            </ModalDiag>
          </ModalContainer>
        )}
      </Modal>
      {MakePay && (
        <MakePayment
          show={MakePay}
          ModalHide={() => setMakePay(false)}
          makepayment="no"
          InvoiceList={OneInvoiceList}
          defaultcardcheck={props.defaultcardcheck}
          CardDetails={cardDetails}
          patient_balance={props.Data.total_balance}
        />
      )}
      {ModalAlerShow && (
        <StripeAlert show={ModalAlerShow} handleModalClick={handleModalClick} />
      )}
    </>
  );
};

export default InvoicePopup;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getDocFirstSave } from "../../../StateManagement/Reducers/DocumentsState";
import CreateSignature from "../CreateSignature";
import Error from "../../../Modules/StyledComponents/Toastify/Error";
import "./Test.css";
import {
  ModalTitle,
  Modal,
  ModalContainer,
  Div,
  Text,
} from "../../StyledComponents";
import {
  CancelButton,
  ModalDialog,
  Preview,
  SaveButton,
  TextArea,
  TextBox,
} from "./Styles";

import { BsXLg } from "react-icons/bs";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

const ViewEdit = ({
  show,
  close,
  status,
  filename,
  filestatus,
  fileid,
  forsign,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({});
  const [name, setName] = useState("");
  const [Signature, setSignature] = useState(false);
  const [ModalAnimat] = useState(true);
  const [disabledsave, setdisabledsave] = useState(false);
  const Refresh = useSelector((state) => state.Document.Refresh);
  const ViewEditDocumentData = useSelector(
    (state) => state.Document.ViewEditData
  );
  console.log(ViewEditDocumentData);
  const Regaxcheck = /}/g;
  const checkEditOrNot = ViewEditDocumentData.match(Regaxcheck);

  const extractValuesBetweenTildes = (val, placeholder) => {
    const regex = /~(.*?)~/g;
    const matches = [];
    let match;

    while ((match = regex.exec(val)) !== null) {
      matches[match.index - placeholder.length] = match[1];
    }

    return matches;
  };

  const extractValuesBetweenTildesEdit = (inputString) => {
    const regex = /~(.*?)~/g;
    const matches = [];
    let match;

    while ((match = regex.exec(inputString)) !== null) {
      matches.push({
        position: match.index,
        value: match[1],
      });
    }

    return matches;
  };

  useEffect(() => {
    const extractedValues =
      extractValuesBetweenTildesEdit(ViewEditDocumentData);

    const valuesToUpdate = extractedValues.reduce(
      (acc, { position, value }) => {
        acc[position] = value;
        return acc;
      },
      {}
    );

    setInputValues((prevValues) => ({
      ...prevValues,
      ...valuesToUpdate,
    }));
  }, [ViewEditDocumentData]);

  const handlePlaceholderReplace = (placeholder, index) => {
    const id = placeholder.replace(/{|}/g, ""); // Remove curly braces
    const value = inputValues[id] || "";

    var valueofArray = extractValuesBetweenTildes(
      ViewEditDocumentData,
      placeholder
    );

    if (placeholder === "{TextInput}") {
      return `<input type="text" class="inputbox" data-input-id="${index}" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : value
      }"></input>`;
    } else if (placeholder === "{DateTimePicker}") {
      return `<input type="datetime-local" class="datetimepicker" data-input-id="${index}" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : value
      }"></input>`;
    } else if (placeholder === "{DatePicker}") {
      return `<input type="date" data-input-id="${index}" class="datepicker" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : value
      }"></input>`;
    } else if (placeholder === "{ynRadioGroup}") {
      return `<input type="radio" class="radiobox" data-input-id="${index}" value="Yes" name="${index}ynRadioGroup" 
     ${
       valueofArray[index] !== undefined && valueofArray[index] === "Yes"
         ? "checked"
         : ""
     }
      >Yes</input>
      <input type="radio" class="radiobox" data-input-id="${index}" value="No" name="${index}ynRadioGroup" ${
        valueofArray[index] !== undefined && valueofArray[index] === "No"
          ? "checked"
          : ""
      }>No</input>`;
    } else if (placeholder === "{TextBox:36x80}") {
      return `<input type="input" class="textbox" data-input-id="${index}" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : value
      }"></input>`;
    } else if (placeholder === "{smTextInput}") {
      return `<input type="text" class="sminput" data-input-id="${index}" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : value
      }"></input>`;
    } else if (placeholder === "{CheckMark}") {
      return `<input type="checkbox" class="checkbox" data-input-id="${index}" ${
        valueofArray[index] !== undefined && valueofArray[index] === "YES"
          ? "checked"
          : ""
      }></input>`;
    } else if (placeholder === "{sizedTextInput:120px}") {
      return `<input type="input" class="sizedinput" data-input-id="${index}" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : value
      }"></input>`;
    } else {
      // Handle other placeholders or provide a default behavior
      return placeholder;
    }
  };
  const ModifiedStringFin = ViewEditDocumentData.replace(
    /{[^}]+}/g,
    (match, index) => handlePlaceholderReplace(match, index)
  );

  const ModifiedStringFinal = ModifiedStringFin.replace(/~[^~]*~/g, "");

  const duplicateString = useSelector((state) => state.Document.ViewEditData);

  useEffect(() => {
    const handleInputChange = (id, value, input) => {
      const inputValue =
        input.type === "radio"
          ? value
          : input.type === "checkbox"
          ? input.checked
            ? "YES"
            : "NO"
          : input.value;
      setInputValues((prevValues) => ({ ...prevValues, [id]: inputValue }));
    };

    const inputs = document.querySelectorAll("[data-input-id]");

    const addEventListeners = () => {
      inputs.forEach((input) => {
        const id = input.getAttribute("data-input-id");

        input.addEventListener("change", (e) =>
          handleInputChange(id, e.target.value, input)
        );
      });
    };

    const removeEventListeners = () => {
      inputs.forEach((input) => {
        const id = input.getAttribute("data-input-id");

        input.removeEventListener("change", (e) =>
          handleInputChange(id, e.target.value)
        );
      });
    };

    addEventListeners();

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      removeEventListeners();
    };
  }, [ModifiedStringFinal /* add other dependencies as needed */]);

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const CloseSignature = () => {
    setName("");
    close();
  };
  const SaveSignature = () => {
    const regex = /}~/g;
    const regex1 = /}/g;

    // Use match method to find all occurrences of the pattern
    const content = ViewEditDocumentData.replace(/{CheckMark}/g, "");
    const matches = content.match(regex);
    const matches1 = content.match(regex1);

    if (matches1 === null || matches1 === undefined) {
      setSignature(true);
    } else {
      if (matches && matches1 && matches.length === matches1.length) {
        setSignature(true);
      } else {
        toast(<Error msg="To Sign, Fill Your Details and Save" />, {
          containerId: "B",
          className: "error_badge",
        });
      }
    }
  };

  /////////////////////////////////ADD////////////////////
  const handlePlaceholderReplaceSET = (placeholder, index) => {
    const id = placeholder.replace(/{|}/g, ""); // Remove curly braces
    const value = inputValues[index] || "";
    if (placeholder === "{TextInput}" && value !== "") {
      return `{TextInput}~${value}~`;
    } else if (placeholder === "{DatePicker}" && value !== "") {
      return `{DatePicker}~${value}~`;
    } else if (placeholder === "{CheckMark}" && value !== "") {
      return `{CheckMark}~${value}~`;
    } else if (placeholder === "{TextBox:36x80}" && value !== "") {
      return `{TextBox:36x80}~${value}~`;
    } else if (placeholder === "{ynRadioGroup}" && value !== "") {
      return `{ynRadioGroup}~${value}~`;
    } else if (placeholder === "{sizedTextInput:120px}" && value !== "") {
      return `{sizedTextInput:120px}~${value}~`;
    } else if (placeholder === "{DateTimePicker}" && value !== "") {
      return `{DateTimePicker}~${value}~`;
    } else if (placeholder === "{smTextInput}" && value !== "") {
      return `{smTextInput}~${value}~`;
    } else {
      // Handle other placeholders or provide a default behavior
      return placeholder;
    }
  };
  const HanldeCloseSignature = () => {
    setSignature(false);
  };
  const HanldeCloseSignatureSave = () => {
    setSignature(false);
    close();
  };
  const setStringreplace = () => {
    if (duplicateString.includes("~")) {
      const modifiedStringEditSave = duplicateString.replace(
        /{[^}]+}/g,
        (match, index) => handlePlaceholderReplaceSET(match, index)
      );
      const cleanedString = modifiedStringEditSave.replace(/~~[^~]+~/g, "~");

      dispatch(
        getDocFirstSave({
          content: cleanedString,
          id: fileid,
          type: "Edit",
        })
      );
      CloseSignature();
    } else {
      const modifiedStringEditSave = duplicateString.replace(
        /{[^}]+}/g,
        (match, index) => handlePlaceholderReplaceSET(match, index)
      );

      dispatch(
        getDocFirstSave({
          content: modifiedStringEditSave,
          id: fileid,
          type: "Add",
        })
      );
      CloseSignature();
    }
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //
  // useEffect(() => {
  //   setModifiedString(modifiedStringEdit);
  // }, [modifiedStringEdit]);

  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Modal show={show} height="100%" width="100%">
        <ModalContainer justifyContent="center">
          <ModalDialog animation={ModalAnimat}>
            <Div
              borderBottom="1px solid #2E2E2E"
              height="50px"
              padding="0px 24px 0px 24px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0px 0px 8px 0px"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="18px"
                fontWeight="600"
                color="#0a1629"
              >
                Edit Document
              </ModalTitle>
              <BsXLg
                style={{
                  color: globalColor,
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={close}
              />
            </Div>
            <Div padding="0px 24px 0px 24px">
              <Div padding="20px" display="flex" justifyContent="space-between">
                <ModalTitle></ModalTitle>
                <ModalTitle
                  lineHight="27px"
                  fontSize="18px"
                  fontWeight="600"
                  color="#0a1629"
                >
                  {filename}
                </ModalTitle>

                <ModalTitle
                  lineHight="27px"
                  fontSize="18px"
                  fontWeight="600"
                  color="#138808"
                >
                  Status: {filestatus}
                </ModalTitle>
              </Div>

              <p dangerouslySetInnerHTML={{ __html: ModifiedStringFinal }} />
            </Div>
            <Div
              padding="24px"
              display="flex"
              flexDirection="row"
              margin="auto 0px 0px 0px"
            >
              {forsign === "1" && (
                <Div>
                  <CancelButton padding="8px 32px" onClick={SaveSignature}>
                    Sign
                  </CancelButton>
                </Div>
              )}
              {checkEditOrNot !== null && (
                <>
                  <CancelButton
                    padding="8px 24px"
                    marginLeft="auto"
                    onClick={CloseSignature}
                    id="sig_cnl-btn"
                    className="close_active"
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton
                    disabled={disabledsave}
                    onClick={setStringreplace}
                    className="save_active"
                  >
                    Save
                  </SaveButton>
                </>
              )}
            </Div>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      <CreateSignature
        show={Signature}
        close={HanldeCloseSignature}
        closesave={HanldeCloseSignatureSave}
        ids={fileid}
        content={ViewEditDocumentData}
      />
    </>
  );
};

export default ViewEdit;

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";

import { NormalImage, Div } from "../../StyledComponents";
import { Container, ImageContainer, Image } from "../StyledComponents";
import { TitleBlock, Title, SubTitle, ClientButton, Status } from "./Styles";

import Login from "../../../Assets/images/login.png";
import Sign from "../../../Assets/images/SignUp.png";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

const WelcomeRight = () => {
  const dispatch = useDispatch();
  const text = useSelector((state) => state.PreLogin.logo);
  console.log(text);
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  const handleMode = (a) => {
    localStorage.removeItem("Mode");
    if (a === "1") {
      let mode = "new";
      dispatch(setMode("new"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    } else {
      let mode = "old";
      dispatch(setMode("old"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };

  return (
    <Container width="100%">
      <Div width="50%" marginTop="130px" margin="auto" textAlign="center">
        {/* <ImageContainer marginTop="72px" marginBottom="65px">
          <Image
            loading="lazy"
            src={File_URL + text}
            height="140px"
            width="200px"
            objectFit="contain"
          />
        </ImageContainer> */}
        <TitleBlock>
          <Title color={globalColor}>Welcome back!&nbsp;</Title>
          <Title color="#000000">Nice to see you again</Title>
        </TitleBlock>
        <SubTitle>
          Use the email your practitioner has on file to sign in—no password
          necessary.
        </SubTitle>
        <Div display=" flex" justifyContent="center" marginTop=" 44px">
          <ClientButton
            marginright="40px"
            to="/InstantSignIn/New"
            exact="true"
            onClick={() => handleMode("1")}
          >
            <NormalImage
              loading="lazy"
              src={Sign}
              alt="Sign-Up Image"
              width="186px"
              height="138px"
            ></NormalImage>
            <Status>I’M A NEW CLIENT</Status>
          </ClientButton>
          <ClientButton
            to="/InstantSignIn/Old"
            exact="true"
            onClick={() => handleMode("2")}
          >
            <NormalImage
              loading="lazy"
              src={Login}
              alt="Login-Image"
              width="186px"
              height="138px"
              id="wel_img-ext"
            />
            <Status>EXISTING CLIENT</Status>
          </ClientButton>
        </Div>
      </Div>
    </Container>
  );
};

export default WelcomeRight;

import styled from "styled-components";
import { globalFont, hoverColor } from "../../utils/RepeatFunctions/Schedule";

export const TitleDiv = styled.div`
  display: flex;
  margin-top: 8px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  margin-top: 11px;
  margin-bottom: 5px;
`;

export const DropdownContent = styled.div`
  display: ${(p) => (p.display === "true" ? "block" : "none")};
  overflow: hidden;
  z-index: 900;
  position: absolute;
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  animation: fadeIn 0.3s;
  top: ${(p) => p.top && p.top};

  left: ${(p) => p.left && p.left};
  & div:first-of-type svg {
    color: #667085;
  }

  @keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-o-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: ${(p) => p.height && p.height};
    }
  }
`;

export const DropdownList = styled.div`
  padding: ${(p) => p.padding && p.padding};
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  transition-duration: 0.3s;
  &:hover {
    background: #ececec;
  }
`;

export const TableDiv = styled.div`
  position: relative;
  border-top: 1px solid #e4e4e4;
  margin-top: 17px;
  margin-left: -40px;
  padding-top: 3px;
`;

export const EditText = styled.span`
  font-size: 36px;
  margin-top: -15px;
  border-radius: 136px;
  cursor: pointer;
`;

export const ProfileNameText = styled.p`
  width: 28px;
  height: 28px;
  background: #a8aebe;
  border-radius: 50%;
  margin-right: 12px;
  font-family: ${globalFont};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusDiv = styled.div`
  height: 24px;
  float: right;
  text-align: center;
  background: ${hoverColor};
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  gap: 10px;
  background: ${(props) => props.background && props.background};
  width: ${(props) => props.width && props.width};
`;

export const StatusText = styled.p`
  height: 18px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color && props.color};
  margin-top: 2px;
`;

export const Emptydatadiv = styled.img`
  width: 308px;
  height: 243px;
  text-align: center;
  padding-top: 3%;
  padding-left: 36%;
`;

export const CommonDiv = styled.div`
  margin-right: ${(props) => props.marginRight && props.marginRight};
`;

export const Iframe = styled.iframe`
  display: none;
  @media print {
    display: block;
  }
`;

export const LinkA = styled.a`
  display: none;
  @media print {
    display: block;
  }
`;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GetList } from "../../../StateManagement/Reducers/HealthProfileState";

import {
  Div,
  Table,
  TableHead,
  TableHeadCell,
  TableBodyCell,
  TableRow,
  TableBody,
  SpanCont,
  Image,
} from "../../StyledComponents";
import { AvatarFrame, Avatar, AvatarLetter, AvatarImage } from "./styles";

import EmptyData from "../../StyledComponents/EmptyData";
import padImage from "../../../Assets/images/Vector.png";
import { TitleLabel } from "../../HomeWork/Styles";
import moment from "moment";
import { globalFont } from "../../utils/RepeatFunctions/Schedule";
import Loading from "../../StyledComponents/Loading";
const Group = (props) => {
  const dispatch = useDispatch();

  const [cell, setCell] = useState("problem");

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  const HealthLoading = useSelector(
    (state) => state.HealthProfile.HealthLoading
  );

  const GroupData = useSelector((state) => state.HealthProfile.List);
  const dateFormat1 = moment(
    localStorage.getItem("dateFormat") ? localStorage.getItem("dateFormat") : ""
  );
  const dateformat = dateFormat1._i;
  useEffect(() => {
    if (props.type === "medical_problem") {
      setCell("Problem");
    } else {
      if (props.type === "allergy") {
        setCell("Allergy");
      } else {
        setCell("Lab");
      }
    }
    dispatch(GetList(props.type));
  }, [props.type]);

  return (
    <>
      {HealthLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <Div margin="20px 0px 0px 0px" backColor="none">
          <Table tableLayout="fixed">
            <TableHead background="#f7f7f7">
              <TableRow>
                <TableHeadCell
                  color="#464646"
                  lineHeight="19.12px"
                  padding="12px 20px 12px 52px"
                  fontWeight="500"
                  width="40%"
                >
                  {cell}
                </TableHeadCell>
                <TableHeadCell
                  color="#464646"
                  lineHeight="19.12px"
                  padding="12px"
                  fontWeight="500"
                  width="30%"
                >
                  Date Diagnosed
                </TableHeadCell>
                <TableHeadCell
                  color="#464646"
                  lineHeight="19.12px"
                  padding="12px 0px 12px 45px"
                  fontWeight="500"
                  width="30%"
                >
                  Provider
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {GroupData.length !== 0 ? (
                <>
                  {GroupData.slice(0, 3).map((row, ind) => (
                    <TableRow key={ind}>
                      <TableBodyCell
                        bodered
                        textAlign="left"
                        color="#263446"
                        lineHeight="19.12px"
                        padding="12px 12px 12px 20px"
                        fontWeight="500"
                      >
                        <Div
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <AvatarFrame marginRight="14px">
                            <Image
                              loading="lazy"
                              src={padImage}
                              padding="4px 0px 0px 5px"
                            />
                          </AvatarFrame>

                          <TitleLabel
                            fontFamily={globalFont}
                            fontSize="14px"
                            fontHeight="19.12px"
                            fontWeight="500"
                            color="#263446"
                            title={row.title}
                            paddingTop="0px"
                          >
                            {row.title}
                          </TitleLabel>
                        </Div>
                      </TableBodyCell>
                      <TableBodyCell
                        bodered
                        textAlign="left"
                        color="#7D8592"
                        lineHeight="19.12px"
                        padding="12px"
                        fontWeight="500"
                      >
                        {cell === "Problem" || cell === "Lab"
                          ? moment(row.date).format(dateformat)
                          : moment(row.dateonly).format(dateformat)}
                      </TableBodyCell>
                      <TableBodyCell
                        bodered
                        textAlign="left"
                        color="#263446"
                        lineHeight="20px"
                        padding="12px 20px 12px 12px"
                        fontWeight="500"
                      >
                        <Div display="flex">
                          {row.providerImage === null ? (
                            <Avatar
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <AvatarLetter>
                                {row.providerName.charAt(0).toUpperCase()}
                              </AvatarLetter>
                            </Avatar>
                          ) : (
                            <AvatarImage
                              src={File_URL + row.providerImage}
                              alt="image-failed"
                              loading="lazy"
                              marginRight="0px"
                            />
                          )}

                          <SpanCont
                            fontFamily={globalFont}
                            color="#263446"
                            lineHeight="20px"
                            fontWeight="400"
                            fontSize="14px"
                            marginLeft="10px"
                          >
                            {row.providerName}
                          </SpanCont>
                        </Div>
                      </TableBodyCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableBodyCell
                    padding="0px 20px 0px 20px"
                    colSpan={3}
                    textAlign="center"
                  >
                    <EmptyData width="175px" height="175px" />
                  </TableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Div>
      )}
    </>
  );
};

export default Group;

import React, { memo } from "react";

import ImageType from "../../Assets/images/imageType.png";
import PdfType from "../../Assets/images/pdfType.png";
import OtherType from "../../Assets/images/otherType.png";
import EmptyData from "../../Assets/images/Emp_data_pic.png";
import VideoIcon from "../../Assets/images/VideoResourceIcon.png";

import {
  FlexJustCenter,
  Table,
  TableBody,
  TableBodyCell,
  TableHeadCell,
  TableRow,
} from "../StyledComponents";
import {
  Avatar,
  AvatarImage,
  AvatarLetter,
  Image,
  StatusLabel,
  TableContainer,
  TableHead,
  TitleLabel,
} from "./Styles";
import { globalFont } from "../utils/RepeatFunctions/Schedule";

const ListView = ({ data, handleOpen }) => {
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const filetypeicon = (filename) => {
    var extentions = filename.split(".").pop();

    if (
      extentions === "jfif" ||
      extentions === "jpg" ||
      extentions === "bmp" ||
      extentions === "png"
    ) {
      return (
        <Image
          src={ImageType}
          alt=""
          loading="lazy"
          width="24px"
          height="24px"
          margin
        />
      );
    } else if (
      extentions === "doc" ||
      extentions === "docx" ||
      extentions === "txt"
    ) {
      return (
        <Image
          src={OtherType}
          alt=""
          loading="lazy"
          width="24px"
          height="24px"
          margin
        />
      );
    } else if (extentions === "pdf") {
      return (
        <Image
          src={PdfType}
          alt=""
          loading="lazy"
          width="24px"
          height="24px"
          margin
        />
      );
    } else {
      return (
        <Image
          src={VideoIcon}
          alt=""
          loading="lazy"
          width="24px"
          height="24px"
          margin
        />
      );
    }
  };
  return (
    <>
      <TableContainer minHeight="400px">
        <Table>
          <TableHead background="#F7F7F7" height="56px" display="sticky">
            <TableRow>
              <TableHeadCell fontFamily={globalFont}>Title</TableHeadCell>
              <TableHeadCell fontFamily={globalFont}>Description</TableHeadCell>
              <TableHeadCell fontFamily={globalFont}>Uploaded By</TableHeadCell>
              {/* <TableHeadCell fontFamily="Manrope" width="170px">
                Status
              </TableHeadCell> */}
            </TableRow>
          </TableHead>
          <TableBody overflowY="auto" height="calc(100% - 56px)">
            {data.length !== 0 ? (
              data.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleOpen(item)}
                  cursor
                  height="90px"
                >
                  <TableBodyCell padding="12px 40px 12px 38px">
                    <FlexJustCenter>
                      {/* <Image
                        src={
                          item.fileType === "Worksheet"
                            ? PdfType
                            : item.fileType === "Video"
                            ? ImageType
                            : OtherType
                        }
                        margin
                      /> */}
                      {filetypeicon(item.fileUrl)}
                      <TitleLabel
                        fontFamily={globalFont}
                        fontSize="14px"
                        fontHeight="16px"
                        fontWeight="normal"
                        color="#000000"
                        title={item.title}
                        paddingTop="0px"
                      >
                        {item.title}
                      </TitleLabel>
                    </FlexJustCenter>
                  </TableBodyCell>
                  <TableBodyCell
                    wordBreak="break-word"
                    padding="12px 40px 12px 38px"
                  >
                    {item.description}
                  </TableBodyCell>
                  <TableBodyCell padding="12px 40px 12px 38px">
                    <FlexJustCenter>
                      {item.imgUrl ? (
                        <AvatarImage
                          src={File_URL + item.img_url}
                          alt="image-failed"
                          loading="lazy"
                          width="40px"
                          height="40px"
                          border="1px solid #ffffff"
                        />
                      ) : (
                        <Avatar width="28px" height="28px">
                          <AvatarLetter
                            display="flex"
                            justifyContent="center"
                            paddingTop="6px"
                          >
                            {item.UploadedBy.charAt(0).toUpperCase()}
                          </AvatarLetter>
                        </Avatar>
                      )}
                      {item.UploadedBy}
                    </FlexJustCenter>
                  </TableBodyCell>
                  {/* <TableBodyCell
                    padding="11px 40px 11px 0px"
                    textAlign="center"
                    width="170px"
                  >
                    <StatusLabel
                      color={item.privacy === "Privacy" ? "#5542F6" : "#20C9AC"}
                      background={
                        item.privacy === "Privacy"
                          ? "rgba(85, 66, 246, 0.1)"
                          : "rgba(32, 201, 172, 0.1)"
                      }
                    >
                      {item.privacy}
                    </StatusLabel>
                  </TableBodyCell> */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableBodyCell colSpan={4} textAlign="center">
                  <Image src={EmptyData} height="133px" width="133px" />
                </TableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(ListView);

import styled from "styled-components";
import { Text, Div } from "../../StyledComponents";
import { globalFont } from "../../utils/RepeatFunctions/Schedule";

export const ModalDailog = styled.div`
  width: 25.5%;
  opacity: 1;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  box-shadow: 5px 5px 8px rgba(20, 46, 110, 0.15);
  animation: show 0.5s;
  display: ${(p) => (p.show ? "block" : "none")};

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ModalBody = styled.div`
  color: ${({ color }) => (color === "success" ? "#198C55" : "#CC0000")};
  font-weight: 500;
  padding: 38px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    color: ${({ color }) => (color === "success" ? "#198C55" : "#CC0000")};
    margin-right: 12px;
    height: 18px;
    width: 18px;
    margin-top: -4px;
  }
`;

export const TextVariant = styled(Text)`
  font-size: 16px;
  color: #263446;
  margin: 8px 0px 0px 0px;

  @media (max-width: 1440px) {
    font-size: 14px;
  }
`;

export const TextVariant2 = styled(Text)`
  ${(p) => p.width && `width : ${p.width};`}
  font-size : 14px;
  color: #212121;
  text-align: left;
  margin-right: ${({ mr }) => mr && mr};
  ${(p) => p.breakAll && `word-break: break-all;`}

  @media (max-width: 3000px) {
    font-size: 14px;
    margin-right: 6px;
  }
  @media (max-width: 1350px) {
    font-size: 13px;
    margin-right: 6px;
  }
  @media (max-width: 1100px) {
    font-size: 12px;
    margin-right: 6px;
  }
  @media (max-width: 950px) {
    font-size: 10px;
    margin-right: 6px;
  }
`;

export const DivVariant = styled(Div)`
  display: flex;
  padding: 12px 0px 0px 12px;
  border-radius: 6px;

  ${({ type }) =>
    type === "office"
      ? `@media (width: 1440px) {
        padding: 6px 0px 0px 12px;
      }`
      : `@media (width: 1440px) {
        padding: 10px 0px 0px 12px;
      }`}

  @media (width: 1024px) {
    padding: 10px 0px 0px 8px;
  }
`;

export const FlexText = styled.p`
  font-family: ${globalFont};
  font-size: 12px;
  line-height: 18px;
  color: #2e2c34;
  display: flex;
  align-items: center;
`;

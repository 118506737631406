import styled from "styled-components";
import { globalFont } from "../../utils/RepeatFunctions/Schedule";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  max-width: 650px;
  position: absolute;
  right: 24px;
  margin-top: 18px;
`;

export const PaginationAnch = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active === "active" ? "#2e2c34" : "#000")} ;;
  height: 42px;
  width: 42px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  background:${(props) => (props.active === "active" ? "#fff" : "#fbfafc")} ;
  border-radius: 4px;
  margin-right: 16px;
  border: 1px solid ${(props) =>
    props.active === "disabled" ? "#ebeaed" : "#2e2c34"};  
  font-family: ${globalFont};
  font-weight: ${(props) => (props.active === "active" ? 600 : "")}  
  font-size: 14px;
  line-height: 20px;
  &:hover:not(.active) {
    background-color: rgb(238, 238, 238);
  }
`;

export const PagePrevNext = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active === "active" ? "#2e2c34" : "#fff")} ;
  display: ${(props) => (props.active === "disabled" ? "none" : "flex")}; 
  height: 42px;
  width: 80px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  background:${(props) => (props.active === "active" ? "#fff" : "#2E2C34")} ;
  border-radius: 4px;
  margin-right: 16px;
  border: 1px solid ${(props) =>
    props.active === "disabled" ? "#ebeaed" : "#2e2c34"};  
  font-family: ${globalFont};
  font-weight: ${(props) => (props.active === "active" ? 600 : "")}  
  font-size: 14px;
  line-height: 20px;
  &:hover:not(.active) {
    background-color: rgb(14 12 12);
  }
`;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

import { FiDownload } from "react-icons/fi";
import moment from "moment";

import {
  getUpdateDocumentslist,
  getDeleteDocumentslist,
  getPreviewDocument,
  getViewEditDocument,
  getDeleteTempDoc,
  getPreviewDownload,
  getDownloadCredentials,
  getDownloadLabCredentials,
  getDeleteLabCredentials,
  setDeleteLabCredentials,
  getDocumentslist,
} from "../../../StateManagement/Reducers/DocumentsState";

import CreateSignature from "../CreateSignature";
import ViewEdit from "../ViewEdit";
import EmptyData from "../../StyledComponents/EmptyData";
import Error from "../../../Modules/StyledComponents/Toastify/Error";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  Image,
  FlexJustCenter,
} from "../../StyledComponents";
import {
  TableDiv,
  ProfileNameText,
  StatusDiv,
  StatusText,
  DropdownList,
  DropdownContent,
  Iframe,
  LinkA,
} from "./styles";

import { BsThreeDots } from "react-icons/bs";
import pencil from "../../../Assets/images/pencil.png";
import Download from "../../../Assets/images/Download.png";
import Delete from "../../../Assets/images/Delete.png";
import forward from "../../../Assets/images/forward.png";
import printer from "../../../Assets/images/printer.png";
import { TitleLabel } from "../../HomeWork/Styles";
import {
  globalColor,
  globalFont,
  hoverColor,
} from "../../utils/RepeatFunctions/Schedule";

const DocumentTable = ({ docTableData, documenthead }) => {
  const dispatch = useDispatch();
  const componentRef = useRef();

  const [editopen, setEditopen] = useState("");
  const [iscreate, setIsCreate] = useState();
  const [forprint, setforprint] = useState("");
  const [tosign, settosign] = useState("");
  const [documentlistdata, setdocumentlistdata] = useState([]);
  const [donwloadprintid, setdonwloadprintid] = useState("");
  const [ForViewEdit, setForViewEdit] = useState(false);
  const [FileName, setFileName] = useState("");
  const [FileStatus, setFileStatus] = useState("");
  const [ForSign, setForSign] = useState("");
  const [FileId, setFileId] = useState("");
  const dateFormat1= moment(
    localStorage.getItem("dateFormat")
      ? localStorage.getItem("dateFormat")
      : ""
  );
  const dateformat = dateFormat1._i;
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }
  const FileURL = backurl + "/src/Services/documentimg/";
  const LabFileURL = backurl + "/sites/" + DBhost + "/LabDocuments/";
  const FileURLProfile = backurl + "/sites/" + DBhost + "/documentimg/";

  const PreviewDownload = useSelector(
    (state) => state.Document.previewdownload
  );
  const DocumentDownloadUrl = useSelector(
    (state) => state.Document.DownloadDocumentlist
  );
  const LabDocumentDownloadUrl = useSelector((state) => state.Document.LabPdf);
  const setEditAction = (ind) => {
    if (editopen === ind) {
      setEditopen("");
    } else {
      setEditopen(ind);
    }
  };

  const SentToReview = (ind, id, status) => {
    if (status === "In Review") {
      toast(<Error msg="This Document Already Sent to Review" />, {
        containerId: "B",
        className: "error_badge",
      });

      setEditAction(ind);
    } else if (status === "Locked") {
      toast(<Error msg="This Document is Already Locked" />, {
        containerId: "B",
        className: "error_badge",
      });
      setEditAction(ind);
    } else {
      dispatch(getUpdateDocumentslist({ ids: id }));

      setEditAction(ind);
    }
  };

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => dispatch(getDeleteTempDoc({ id: donwloadprintid })),
  });

  const FileDownload = async (fileurl, id, ind) => {
    setdonwloadprintid(id);
    dispatch(getPreviewDownload({ file: fileurl, id: id, testurl: FileURL }));
    setEditAction(ind);
  };

  const HandleDelete = (ind, id) => {
    if (window.confirm("Are you sure you want to Delete?")) {
      dispatch(getDeleteDocumentslist({ ids: id }));

      setEditAction(ind);
    }
  };

  const PreviewDocument = (id, fileurl, print, ind) => {
    setdonwloadprintid(id);
    if (print === "yes") {
      setEditAction(ind);
      setforprint("yes");
    }
    dispatch(getPreviewDocument({ file: fileurl, id: id }));
  };
  const PreviewDocumentEdit = (id, fileurl, fname, status, sign) => {
    setForViewEdit(true);
    setFileName(fname);
    setFileStatus(status);
    setForSign(sign);
    setFileId(id);
    // setdonwloadprintid(id);
    // if (print === "yes") {
    //   setEditAction(ind);
    //   setforprint("yes");
    // }
    // dispatch(getPreviewDocument({ file: fileurl, id: id }));
    dispatch(getViewEditDocument({ file: fileurl, id: id }));
  };

  const HanldeSignature = (ind, id, status) => {
    if (status === "In Review") {
      toast(<Error msg="This Document Already Signatured!." />, {
        containerId: "B",
        className: "error_badge",
      });

      setEditAction(ind);
    } else {
      settosign(id);
      setIsCreate(true);
      setEditAction(ind);
    }
  };

  const HanldeCloseSignature = () => {
    setIsCreate(false);
    settosign("");
  };
  const HanldeCloseViewEdit = () => {
    setForViewEdit(false);
    dispatch(getDocumentslist({ filtertype: "All Documents" }));
  };

  const getFileExtension = (filename) => {
    const extension = filename.split(".").pop();
    return extension;
  };

  const handleDownload = (value) => {
    dispatch(getDownloadCredentials({ order_id: value, result: 1 }));
  };

  const handleDownloadLab = (order_id, id, pid, testcode, filename) => {
    dispatch(
      getDownloadLabCredentials({
        order_id: order_id,
        id: id,
        pid: pid,
        testcode: testcode,
        filename: filename,
      })
    );
  };

  const handleClick = (e) => {
    if (!e.target.id) {
      setEditopen("");
    }
  };

  // ------------------------------Functions End-------------------------- //

  // ------------------------------UseEffect Start-------------------------- //
  useEffect(() => {
    if (docTableData !== null || docTableData !== undefined) {
      const sortedArray = docTableData.sort((a, b) => {
        if (a.assignedByDefault === 1 && b.assignedByDefault !== 1) {
          return -1;
        }

        if (b.assignedByDefault === 1 && a.assignedByDefault !== 1) {
          return 1;
        }

        return b.id - a.id;
      });
      setdocumentlistdata(sortedArray);
    }
  }, [docTableData]);

  useEffect(() => {
    if (DocumentDownloadUrl !== "") {
      if (forprint === "yes") {
        const fileextension = getFileExtension(DocumentDownloadUrl);
        if (
          fileextension === "png" ||
          fileextension === "txt" ||
          fileextension === "jpg" ||
          fileextension === "jpeg" ||
          fileextension === "PNG" ||
          fileextension === "TXT" ||
          fileextension === "JPG" ||
          fileextension === "JPEG"
        ) {
          HandlePrint();
        } else if (fileextension === "pdf" || fileextension === "PDF") {
          document.getElementById("preview_document").click();
          setTimeout(() =>
            dispatch(getDeleteTempDoc({ id: donwloadprintid }), 10000)
          );
        } else {
          toast(<Error msg="File Not Allow to Print." />, {
            containerId: "B",
            className: "error_badge",
          });
        }
        setforprint("");
      } else {
        document.getElementById("preview_document").click();
        setTimeout(() =>
          dispatch(getDeleteTempDoc({ id: donwloadprintid }), 10000)
        );
      }
    }
  }, [DocumentDownloadUrl]);

  useEffect(() => {
    if (LabDocumentDownloadUrl !== "") {
      document.getElementById("lab_preview_document").click();
      setTimeout(() => dispatch(setDeleteLabCredentials(), 20000));
    }
  }, [LabDocumentDownloadUrl]);

  useEffect(() => {
    if (PreviewDownload !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownload.file;
      tag.download = PreviewDownload.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      dispatch(getDeleteTempDoc({ id: donwloadprintid }));
      setdonwloadprintid("");
    }
  }, [PreviewDownload]);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getDeleteLabCredentials({
        filename: LabFileURL + LabDocumentDownloadUrl,
      }),
      20000
    );
  }, []);

  // ------------------------------UseEffect End-------------------------- //

  return (
    <>
      {documenthead === "Lab Results" ? (
        <TableDiv>
          <Iframe
            seamless="seamless"
            width="100%"
            height="800px"
            ref={componentRef}
            src={FileURL + DocumentDownloadUrl}
          />
          <LinkA
            href={FileURL + DocumentDownloadUrl}
            target="_blank"
            id="preview_document"
          />
          <LinkA
            href={LabFileURL + LabDocumentDownloadUrl}
            target="_blank"
            id="lab_preview_document"
          />

          <Table>
            <TableHead>
              <TableRow background="#F7F7F7">
                <TableHeadCell width="19%">Form</TableHeadCell>
                <TableHeadCell width="17%">Title</TableHeadCell>
                <TableHeadCell width="15%" style={{ paddingRight: "20px" }}>
                  Associate Patient
                </TableHeadCell>
                <TableHeadCell width="15%">Created</TableHeadCell>
                <TableHeadCell width="15%">Updated</TableHeadCell>
                <TableHeadCell width="5%">Download</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {documentlistdata.length > 0 ? (
                  <>
                    {documentlistdata.map((row, ind) => (
                      <TableRow background="#FFFFF" key={ind}>
                        <TableBodyCell
                          fontFamily={globalFont}
                          padding="16px 40px 10px 38px;"
                          cursor="pointer"
                          onClick={() => {
                            if (row.name === "lab") {
                              handleDownloadLab(
                                row.order_id,
                                row.procedure_order_id,
                                row.patient_id,
                                row.vendor + "-" + row.tests,
                                row.filename
                              );
                            } else {
                              PreviewDocument(row.id, row.file_url, "no", ind);
                            }
                          }}
                        >
                          {" "}
                          <FlexJustCenter>
                            <TitleLabel
                              fontFamily={globalFont}
                              fontSize="14px"
                              fontHeight="16px"
                              fontWeight="normal"
                              color="#000000"
                              title={row.vendor}
                            >
                              {row.vendor}
                            </TitleLabel>
                          </FlexJustCenter>
                        </TableBodyCell>
                        <TableBodyCell
                          fontFamily={globalFont}
                          padding="16px 40px 10px 38px;"
                        >
                          {row.tests}
                        </TableBodyCell>
                        <TableBodyCell
                          display="flex"
                          fontFamily={globalFont}
                          padding="16px 40px 10px 38px;"
                          alignItems="center"
                        >
                          {row.pat_img_url !== null ? (
                            <Image
                              objectFit="cover"
                              loading="lazy"
                              width="28px"
                              height="28px"
                              borderRadius="50%"
                              margin="0 12px 0 0"
                              src={FileURLProfile + row.pat_img_url}
                            />
                          ) : (
                            <ProfileNameText>
                              {row.pat_name.charAt(0).toUpperCase()}
                            </ProfileNameText>
                          )}
                          {row.pat_name}
                        </TableBodyCell>

                        <TableBodyCell padding="16px 40px 10px 38px;">
                          {moment(row.date_submit).calendar(null, {
                            sameDay: dateformat,
                            nextDay: dateformat,
                            nextWeek: dateformat,
                            lastDay: dateformat,
                            lastWeek: dateformat,
                            sameElse: dateformat,
                          })}
                        </TableBodyCell>
                        <TableBodyCell padding="16px 40px 10px 38px;">
                          {moment(row.update_date).calendar(null, {
                            sameDay: dateformat,
                            nextDay: dateformat,
                            nextWeek: dateformat,
                            lastDay: dateformat,
                            lastWeek: dateformat,
                            sameElse: dateformat,
                          })}
                        </TableBodyCell>
                        <TableBodyCell padding="16px 68px 10px 38px;">
                          <StatusDiv
                            width="32px"
                            background="#FFFFFF"
                            style={{
                              boxShadow:
                                "0px 1px 3px rgba(0, 0, 0, 0.07), 0px 0.5px 2px rgba(0, 0, 0, 0.07)",
                              borderRadius: "4px",
                              float: "right",
                              cursor:
                                row.status !== "active"
                                  ? "pointer"
                                  : "not-allowed",
                              height: "32px",
                              display: "flex",
                            }}
                            onClick={() => {
                              if (row.status !== "active") {
                                if (row.name === "lab") {
                                  handleDownloadLab(
                                    row.order_id,
                                    row.procedure_order_id,
                                    row.patient_id,
                                    row.vendor + "-" + row.tests,
                                    row.filename
                                  );
                                } else {
                                  handleDownload(row.order_id);
                                }
                              }
                            }}
                          >
                            <FiDownload
                              style={{
                                height: "18px",
                                width: "18px",
                                color: globalColor,
                                cursor:
                                  row.status !== "active"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                            />
                          </StatusDiv>
                        </TableBodyCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow background="#FFFFF">
                    <TableBodyCell colSpan={5} textAlign="center">
                      <EmptyData />
                    </TableBodyCell>
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableDiv>
      ) : (
        <TableDiv>
          <Iframe
            seamless="seamless"
            width="100%"
            height="800px"
            ref={componentRef}
            src={FileURL + DocumentDownloadUrl}
          />
          <LinkA
            href={FileURL + DocumentDownloadUrl}
            target="_blank"
            id="preview_document"
          />

          <Table>
            <TableHead>
              <TableRow background="#F7F7F7">
                <TableHeadCell width="35%">Document</TableHeadCell>
                <TableHeadCell width="25%">Create Date</TableHeadCell>
                <TableHeadCell width="25%">Shared By</TableHeadCell>
                <TableHeadCell width="8%" padding="20px 0px 20px 70px">
                  Status
                </TableHeadCell>
                <TableHeadCell width="7%"></TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {documentlistdata.length > 0 ? (
                  <>
                    {documentlistdata.map((row, ind) => (
                      <TableRow background="#FFFFF" key={ind}>
                        <TableBodyCell
                          fontFamily={globalFont}
                          padding="16px 40px 10px 38px;"
                          cursor="pointer"
                          width="35%"
                          whiteSpace="normal"
                          overflowWrap="break-word"
                          onClick={() =>
                            row.status === "In Review" ||
                            row.status === "Locked" ||
                            row.is_share !== "yes"
                              ? PreviewDocument(row.id, row.file_url, "no", ind)
                              : row.tags === "By Provider"
                              ? PreviewDocument(row.id, row.file_url, "no", ind)
                              : PreviewDocumentEdit(
                                  row.id,
                                  row.file_url,
                                  row.form_name,
                                  row.status,
                                  row.req_pat_sign
                                )
                          }
                        >
                          <FlexJustCenter>
                            <TitleLabel
                              cursor="pointer"
                              fontFamily={globalFont}
                              fontSize="14px"
                              fontHeight="16px"
                              fontWeight="normal"
                              color="#000000"
                              title={row.form_name}
                            >
                              {row.form_name}
                            </TitleLabel>
                          </FlexJustCenter>
                        </TableBodyCell>
                        <TableBodyCell
                          fontFamily={globalFont}
                          padding="16px 40px 10px 38px;"
                        >
                          {moment(row.date).format(dateformat)}
                        </TableBodyCell>
                        <TableBodyCell
                          display="flex"
                          fontFamily={globalFont}
                          padding="16px 40px 10px 38px;"
                          alignItems="center"
                          width="max-content"
                        >
                          {row.prov_img_url !== null ? (
                            <Image
                              objectFit="cover"
                              loading="lazy"
                              width="28px"
                              height="28px"
                              borderRadius="50%"
                              margin="0 12px 0 0"
                              src={FileURLProfile + row.prov_img_url}
                            />
                          ) : (
                            <ProfileNameText>
                              {row.prov_name_split}
                            </ProfileNameText>
                          )}
                          {row.username}
                        </TableBodyCell>
                        <TableBodyCell padding="16px 0px 10px 38px;">
                          {row.status === "In Review" && (
                            <StatusDiv
                              width="80px"
                              background="rgba(32, 201, 172, 0.1)"
                            >
                              <StatusText color="#20c9ac">
                                {row.status}
                              </StatusText>
                            </StatusDiv>
                          )}
                          {row.status === "New" && (
                            <StatusDiv width="50px" background={hoverColor}>
                              <StatusText color={globalColor}>
                                {row.status}
                              </StatusText>
                            </StatusDiv>
                          )}
                          {row.status === "Locked" && (
                            <StatusDiv
                              width="80px"
                              background="rgba(132, 129, 138, 0.1)"
                            >
                              <StatusText color="#2e2c34">
                                {row.status}
                              </StatusText>
                            </StatusDiv>
                          )}
                        </TableBodyCell>
                        <TableBodyCell padding="16px 40px 10px 38px;">
                          <BsThreeDots
                            id="icon"
                            style={{
                              fontSize: 24,
                              color: "#212121",
                              cursor: "pointer",
                            }}
                            onClick={() => setEditAction(ind)}
                          />
                          <DropdownContent
                            marginLeft="-180px"
                            width="179px"
                            marginRight="16px"
                            display={editopen === ind ? "true" : "false"}
                            share={row.is_share}
                          >
                            {row.is_share !== "yes" && (
                              <>
                                {row.status !== "Locked" && (
                                  <DropdownList
                                    id="list"
                                    padding="0px 0px 3px 20px"
                                    onClick={() =>
                                      SentToReview(ind, row.id, row.status)
                                    }
                                  >
                                    <Image
                                      objectFit="cover"
                                      width="16px"
                                      height="16px"
                                      margin="8px 16px 0px 0px"
                                      src={forward}
                                      id="doc_rvw-snt_img"
                                    />
                                    Sent to Review
                                  </DropdownList>
                                )}
                              </>
                            )}
                            {/* {row.is_share === "yes" && (
                              <>
                                {row.req_pat_sign === "1" && (
                                  <>
                                    {row.status !== "Locked" &&
                                      row.status !== "In Review" && (
                                        <DropdownList
                                          id="list1"
                                          padding="0px 0px 3px 20px"
                                          onClick={() =>
                                            HanldeSignature(
                                              ind,
                                              row.id,
                                              row.status
                                            )
                                          }
                                        >
                                          <Image
                                            objectFit="cover"
                                            width="16px"
                                            height="16px"
                                            margin="8px 16px 0px 0px"
                                            src={pencil}
                                          />
                                          Signature
                                        </DropdownList>
                                      )}
                                  </>
                                )}
                              </>
                            )} */}
                            {(row.status === "In Review" ||
                              (row.is_share !== "yes" &&
                                (row.status === "New" ||
                                  row.status === "Locked" ||
                                  row.status === "In Review"))) && (
                              <>
                                <DropdownList
                                  id="list2"
                                  padding="0px 0px 3px 20px"
                                  onClick={() =>
                                    PreviewDocument(
                                      row.id,
                                      row.file_url,
                                      "yes",
                                      ind
                                    )
                                  }
                                >
                                  <Image
                                    loading="lazy"
                                    width="16px"
                                    height="16px"
                                    margin="0px 16px 0px 0px"
                                    src={printer}
                                  />
                                  Print
                                </DropdownList>
                                <DropdownList
                                  id="list3"
                                  padding="0px 0px 3px 20px"
                                  onClick={() =>
                                    FileDownload(row.file_url, row.id, ind)
                                  }
                                >
                                  <Image
                                    loading="lazy"
                                    width="16px"
                                    height="16px"
                                    margin="0px 16px 0px 0px"
                                    src={Download}
                                  />
                                  Download
                                </DropdownList>
                              </>
                            )}
                            {row.status !== "Locked" && (
                              <DropdownList
                                id="list4"
                                padding="0px 0px 3px 20px"
                                onClick={() => HandleDelete(ind, row.id)}
                              >
                                <Image
                                  loading="lazy"
                                  width="16px"
                                  height="16px"
                                  margin="8px 16px 0px 0px"
                                  src={Delete}
                                />
                                Delete
                              </DropdownList>
                            )}
                          </DropdownContent>
                        </TableBodyCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow background="#FFFFF">
                    <TableBodyCell colSpan={5} textAlign="center">
                      <EmptyData />
                    </TableBodyCell>
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableDiv>
      )}
      <CreateSignature
        show={iscreate}
        close={HanldeCloseSignature}
        ids={tosign}
      />
      <ViewEdit
        show={ForViewEdit}
        filename={FileName}
        filestatus={FileStatus}
        fileid={FileId}
        close={HanldeCloseViewEdit}
        forsign={ForSign}
      />
    </>
  );
};

export default DocumentTable;
